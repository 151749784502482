.editor-container {
    width: 100%;
    height: 400px; /* Adjust this to your desired height */
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto; /* Add scroll if the content exceeds the height */
    background-color: #f9f9fd;
    border-radius: 8px; /* Optional: for rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: for subtle depth */
    text-overflow: ellipsis; /* Optional: handles text overflow */
}
